/* Common CSS */
@import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,500&family=Outfit:wght@300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
li {
  list-style-type: none;
}
input,
textarea,
select,
button {
  font-family: "Outfit", sans-serif;
}
/* a{color: #1897f2;} */
a:hover {
  text-decoration: none;
}
input:focus {
  outline: none;
}
/* input::-webkit-input-placeholder {color: #0b0a0a; opacity: 1;} */
/* input::-moz-placeholder {color: #0b0a0a; opacity: 1;} */
/* input:-ms-input-placeholder {color: #0b0a0a; opacity: 1;} */
/* input:-moz-placeholder {color: #0b0a0a; opacity: 1;} */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}
input::-moz-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}
input:-moz-placeholder,
textarea::-webkit-input-placeholder {
  color: #737584;
  opacity: 1;
}
button {
  cursor: pointer;
}

/* toast css */

/* img{max-width: 100%; display: block;} */
.container_fluid {
  width: 100%;
  float: left;
  overflow-x: hidden;
}
.container_lfluid {
  width: 100%;
  overflow: hidden;
  float: left;
}
.container {
  width: 1440px;
  margin: 0 auto;
  min-height: 100vh;
}
select:focus {
  outline: none;
}
.inputstyle_select {
  width: 100%;
  height: auto;
  border-radius: 4px;
  background-color: #fafafa;
  border: 1px solid #f2f8fd;
  color: #737584;
  padding: 10px 26px 10px 12px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
/* validation css */
.viewcampaign_pagination_dash {
  margin-left: 0px;

  margin-top: 47px;
  /* margin-left: 38px; */
  width: 93%;
  padding-bottom: 26px;
}
.view-all-invoice {
  border-radius: 58px;
  background: #163e6e;
  border: 1px solid #163e6e;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 165%;
  letter-spacing: 0.036px;
  width: 160px;
  height: 40px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-invoice {
  padding-right: 50px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.view-all-notifications {
  border-radius: 58px;
  background: #163e6e;
  border: 1px solid #163e6e;
  font-size: 16px;
  font-weight: 600;
  line-height: 165%;
  letter-spacing: 0.036px;
  width: 205px;
  height: 40px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-all-notifications .view-notifications {
  color: #ffffff;

  text-decoration: none;
}
.view-all-notifications_dash {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.inputError {
  box-sizing: border-box;
  width: 435px;
  height: 46px;
  border: 1px solid red;
  border-radius: 15px;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.inputErrorToggle {
  box-sizing: border-box;
  width: 435px;
  height: 46px;
  border: 1px solid red;
  border-radius: 15px;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #000000;
  display: flex;
  justify-content: space-between;
}
.dashboard_highest {
  text-decoration: underline;
  color: #000000;
}
.inputErrorF {
  box-sizing: border-box;
  width: 381px;
  height: 46px;
  border: 1px solid red;
  border-radius: 15px;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.input_text_error {
  color: red;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.input_text_error_footer {
  color: red;
  font-size: 11px;
  margin-top: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.landing_typograpy_about {
  color: #163e6e;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 20px;
}
.input_text_error_new {
  color: red;
  font-size: 13px;
  padding-top: 2px;
  font-weight: 500;
}

/* landing pages css */

/*--------------- pagenot foun css-------------------------- */

.pagenotfound {
  position: relative;
  background-color: #fff;
  color: #000;
  display: flex;
  font-family: "Outfit", sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.word {
  font-family: "Outfit", sans-serif;
  perspective: 1000px;
  width: 100%;
  text-align: center;
}

.word span {
  cursor: pointer;
  display: inline-block;
  font-size: 100px;
  user-select: none;
  line-height: 0.8;
}

.active_page {
  color: red;
}

.payment_invoice_header_desktop {
  padding-top: 10px;
  margin-bottom: 32px;
  width: 98%;
}
.word span:nth-child(1).active_page {
  animation: balance 1.5s ease-out;
  transform-origin: bottom left;
}

@keyframes balance {
  0%,
  100% {
    transform: rotate(0deg);
  }

  30%,
  60% {
    transform: rotate(-45deg);
  }
}

.word span:nth-child(2).active_page {
  animation: shrinkjump 1s ease-in-out;
  transform-origin: bottom center;
}

@keyframes shrinkjump {
  10%,
  35% {
    transform: scale(2, 0.2) translate(0, 0);
  }

  45%,
  50% {
    transform: scale(1) translate(0, -150px);
  }

  80% {
    transform: scale(1) translate(0, 0);
  }
}

.word span:nth-child(3).active_page {
  animation: falling 2s ease-out;
  transform-origin: bottom center;
}

@keyframes falling {
  12% {
    transform: rotateX(240deg);
  }

  24% {
    transform: rotateX(150deg);
  }

  36% {
    transform: rotateX(200deg);
  }

  48% {
    transform: rotateX(175deg);
  }

  60%,
  85% {
    transform: rotateX(180deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.word span:nth-child(4).active_page {
  animation: rotate 1s ease-out;
}

@keyframes rotate {
  20%,
  80% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.word span:nth-child(5).active_page {
  animation: toplong 1.5s linear;
}

@keyframes toplong {
  10%,
  40% {
    transform: translateY(-10vh) scaleY(1);
  }

  90% {
    transform: translateY(-30vh) scaleY(2);
  }
}

.goback {
  position: absolute;
  top: 66%;
  left: 46%;
  text-align: center;
}

.goback div {
  color: red;
  padding: 10px 0px;
}

.goback a {
  text-decoration: none;
  padding: 10px;
  color: #000;
  background: #f0f0f0;
  border-radius: 10px;
}

/* pop uu css */

.registerpopup_subhead {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  margin-bottom: 10px;
}
.registerpopup_join {
  color: #163e6e;
  font-size: 14px;
  font-weight: 800;
  line-height: 25px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}
.new_join_ehatsapp {
  text-align: center;
  margin-bottom: 20px;
}

.registerpopup_head {
  color: #163e6e;
  font-size: 24px;
  margin-bottom: 30px;

  font-style: normal;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  font-family: "Outfit", sans-serif;
}
/* About us css */
.aboutus_container {
  background-color: #f4faff;
  /* width: 100vw; */
  min-height: 100vh;
  padding-top: 19px;
  height: auto;
  padding-bottom: 20px;
}

.aboutus_container_left ul {
  margin: 0px 0px 24px 39px;
}
.aboutus_container_left {
  width: 20%;
  height: 820px;
  padding-bottom: 20px;
  font-style: normal;
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 6px;
}
.aboutus_container_left ul li a {
  color: rgba(29, 29, 32, 0.74);
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.aboutus_container_left ul li {
  font-family: "Outfit";
}
.aboutus_container_left ul li a:hover {
  color: #163e6e;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.aboutus_container_left ul li:first-child {
  color: #163e6e;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.footer_text_terms,
.footer_text_terms a {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
}
.footer_text_terms a {
  text-decoration-line: underline;
}
.aboutus_container_left_terms {
  margin-top: 24px;
}
.aboutus_container_right {
  width: 75%;
  height: auto;
  padding-bottom: 20px;
  flex-shrink: 0;
  font-family: "Outfit";
  background-color: #ffffff;
  border-radius: 6px;
}
.faq_container_right {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 6px;
}
.contactus_container_right {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  flex-shrink: 0;
  background-color: #ffffff;
  border-radius: 6px;
}

.landing_typograpy,
.terms_typograpy_text {
  color: #163e6e;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Outfit", sans-serif;
}

.terms_typograpy_text_heading {
  color: #163e6e;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Outfit", sans-serif;
  text-align: center;
  margin: 20px 0px;
}
.terms_typograpy_text_sub {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
}
.landing_typograpy_text > ul > li > a {
  text-decoration: none;
  color: #0090ff;
  font-weight: 500;
  font-size: 15px;
}
.landing_typograpy {
  padding: 36px 0px 0px 45px;
}
.terms_typograpy_text {
  padding: 32px 0px 0px 0px;
}
.aboutus_container_right_contant {
  display: flex;
  justify-content: space-between;
  padding: 0px 57px 0px 45px;
  gap: 20px;
}
.privacy_typograpy_text_heading {
  color: #163e6e;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Outfit", sans-serif;
  text-align: center;
  margin: 20px 0px;
}
.landing_typograpy_text,
.landing_typograpy_subtext {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}
.landing_typograpy_subtext {
  margin: 0px 57px 0px 45px;
  padding: 25px 0px;
}
.Aboutus_images {
  width: 313px;
  height: 280px;
  border-radius: 20px;
}

.aboutus_container_innar {
  margin: 0px 95px 0px 95px;
  display: flex;
  justify-content: space-around;
  padding-top: 19px;
}
.faq_container_innar {
  display: flex;
  justify-content: space-around;
  padding-top: 19px;
}
.contactus_container_innar {
  display: flex;
  justify-content: space-around;
  padding-top: 19px;
}

/* footer css */
.footerinner {
  padding: 0px 50px;
  display: flex;
  justify-content: space-between;
}
/* .footerinner_icon a{

  display: flex;
  justify-content: space-between;
  gap: 40px;
} */
.socil-media {
  height: 40px;
  width: 40px;
  text-align: center;
  background-color: #ffffff;
  line-height: 40px;
  font-size: 18px;
  margin: 0px 5px;
  display: inline-block;
  border-radius: 100%;
  margin-bottom: 6px;
}
.footerContainer {
  width: 100%;
  font-family: "Outfit";
  padding: 15px 0px;
  background-color: #163e6e;
  color: #aacbf3;
  font-size: 13px;
}

/* terms css */
.terms_container {
  margin: 0px 87px;
  font-style: normal;
}

.terms_container > p {
  margin-top: 24px;
}
.terms-Of_service_heading {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
}
.terms_Of_service_heading {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
  font-family: "Montserrat", sans-serif;
}
.terms_Of_service_heading_li {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: justify;
  margin-bottom: 16px;
  list-style-type: disc;
  font-family: "Montserrat", sans-serif;
}
.li_heading_terms {
  color: #163e6e;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}
.terms_of_use_heading {
  color: #163e6e;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin: 25px 0px;
  font-family: "Outfit", sans-serif;
}

/* PrivacyComponenet css */
.Information_We_Collect {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 16px;
  text-align: justify;
  list-style: disc;
  font-family: "Montserrat", sans-serif;
}
.Information_We_Collect_span {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}

/* Faq css */
.faq_section_heading {
  color: #163e6e;
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
  margin-top: 25px;
}

.Information_accordian {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
}

/* contactUs css */
.contact_typograpy_heading {
  color: #163e6e;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  margin-top: 33px;
  font-family: "Outfit", sans-serif;
}
.contact_disc {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 22px;
  font-family: "Montserrat", sans-serif;
}

.contact_left ul li {
  color: #163e6e;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  display: flex;
  margin-bottom: 38px;
  font-family: "Outfit", sans-serif;
}
.contact_left ul li:last-child {
  text-align: justify;
}
.contact_left ul li img {
  width: 34px;
  height: 34px;
  margin-right: 14px;
}
.contact_innercontainer {
  display: flex;
  justify-content: space-between;
}

.blog_button {
  display: flex;
  justify-content: center;
}
.blog_button button {
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  background: #163e6e;
  border-style: solid;
  border-radius: 50px;
  border: 1px solid #163e6e;
  padding: 15px 22px;
  font-size: 16px;
  font-weight: 500;
}
.blog_button button:hover {
  color: #163e6e;
  background: #ffffff;
  border: 1px solid #163e6e;
}
.contact_submit {
  background: #163e6e;
  border: 1px solid #163e6e;
  border-radius: 5px;
  width: 121px;
  height: 32px;
  color: #ffffff;
  margin-top: 27px;
  font-family: "Outfit", sans-serif;
}
.contact_submit_disabled {
  border-radius: 5px;
  background: #ebebe4;
  width: 121px;
  height: 32px;
  border: 1px solid #ebebe4;
  color: #c6c6c6;
  margin-top: 27px;
  font-family: "Outfit", sans-serif;
}
.apply_button_job_disabled {
  background: #ebebe4;
  width: 100%;
  border: 1px solid #ebebe4;
  color: #c6c6c6;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  padding: 10px 50px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.Landing_frame {
  position: absolute;
  /* top:638px; */
  top: 543px;
  /* top: 473px; */
  /* top: 420px; */
}
.Landing_frame img {
  width: 100%;
  height: 300px;
}
.submit_container {
  display: flex;
  justify-content: center;
}
.contact_right,
.contact_left {
  width: 45%;
}

.contact_container {
  margin: 0px 57px;
  font-style: normal;
  position: relative;
  /* z-index: 1; */
}
/* home css/ */

.home_container {
  position: relative;
  font-family: "Outfit";
  font-style: normal;

  margin: 0px 40px;
}
.home_container img {
  width: 100%;
}

.home_inner_button button {
  border-radius: 58px;
  background: #fff;
  border: 1px solid #163e6e;
  color: #163e6e;
  font-size: 18px;
  font-weight: 600;
  line-height: 165%;
  letter-spacing: 0.036px;
  width: 304px;
  height: 74px;
}

.home_inner_button {
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
}
.home_inner_container {
  position: absolute;
  top: 170px;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.home_inner_container p {
  width: 459px;
  color: rgba(255, 255, 255, 0.89);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 165%;
  letter-spacing: 0.048px;
}
.home_container h1 {
  color: #fff;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  text-align: center;
  text-align: center;
  font-size: 39px;
  font-weight: 400;
  line-height: 125%;
}

/* blogcard css */
.blogcards_container {
  width: 31%;
  height: auto;
  margin-bottom: 35px;
  padding-bottom: 10px;
  font-style: normal;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.blogcards_container ul li img {
  width: 100%;
  height: 230px;
}
.blogcards_container ul li:nth-child(2) {
  color: #163e6e;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-align: justify;
  padding: 7px 25px 12px 14px;
  text-align: justify;
  font-family: "Outfit", sans-serif;
}
.blogcards_container ul li:nth-child(3) {
  color: #000000;
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
  line-height: 177.5%; /* 23.075px */
  padding: 7px 25px 20px 14px;
  font-family: "Montserrat", sans-serif;
}

.blog_container {
  border-radius: 10px;
  background: #fff;
}

.blog_inner_container {
  font-style: normal;
  padding: 43px 48px 120px;
}
.blog_card_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* gap: 26px; */
}
.Tutorials_inner_container h4 {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  text-align: justify;
  padding: 16px 0px 30px 0px;
  font-family: "Montserrat", sans-serif;
}
.Tutorials_inner_container h3 {
  color: #163e6e;
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
  padding-top: 35px;
  font-family: "Outfit", sans-serif;
}
.blog_inner_container p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  padding: 14px 0px;
  font-family: "Montserrat", sans-serif;
}
.blog_heading {
  color: #163e6e;
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  font-family: "Outfit", sans-serif;
}
.tutortials_heading {
  color: #163e6e;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  padding-top: 39px;
  font-family: "Outfit", sans-serif;
}
.tutorial_video {
  width: 100%;
  height: 587.798px;
}

/* Tutorials_inner_container css */

.Tutorials_inner_container {
  padding: 0px 40px;
  font-style: normal;
}
/*--------------------------user sign up css----------------------------- */
.signup_container {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
}

.signup_forgate_container {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  align-items: center;
}

.signup_container_left h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: #000000;
  margin: 0;
}
.signup_container_left .forgate_heading {
  font-weight: 400;
  font-size: 32px;
  line-height: 33px;
  color: #163e6e;
  margin: 0;
}
.signup_container_left .foregate_typography {
  font-weight: 400;
  font-size: 20px;
  line-height: 26.3px;
  color: #000000;
  text-align: center;
  width: 81%;
  margin-left: 10px;
  margin-top: 7px;
  margin-bottom: 40px;
}
/* .signup_container_left > p {
  font-weight: 500;
  font-size: 15px;
  line-height: 33px;
  color: #000000;
  margin: 0;
  margin-bottom: 20px;
} */
.signup_container_left_typo {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  margin: 0;
  margin-top: 10px;
  /* margin-bottom: 20px; */
}
.signup_container_left_typo2 {
  font-weight: 500;
  font-size: 15px;
  /* line-height: 33px; */
  color: #000000;
  margin: 0;
}
.signup_card {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  border-bottom: 1px solid rgba(151, 151, 151, 0.4);
  padding-bottom: 30px;
  margin-bottom: 23px;
}
.signup_card_img.signup_slected {
  border: 2px solid #163e6e;
}

.login_password {
  display: flex;
  justify-content: space-between;
}
.login_password a {
  color: #1b80e4;
  font-size: 15px;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  line-height: 33px;
  text-decoration: none;
  cursor: pointer;
}
.signup_card_img {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  margin-right: 5px;
  cursor: pointer;
}
.signup_card_img img {
  width: 90px;
  height: 84px;
}
.signup_card_text {
  padding: 0px 10px;
}
.signup_card_text p {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0px;
  color: #163e6e;
  margin: 12px;
}
.signup_card_text h3 {
  font-size: 21px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #1b80e4;
}
.signup_checkbox {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.signup_checkbox p {
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #000000;
  margin-top: 7px;
}
.signup_checkbox span {
  margin-top: 7px;
}
.signup_checkbox span a {
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #000000;
  margin-left: 2px;
}

.login_email_verifiction {
  font-size: 15px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
  text-align: center;
  color: #ff5656;
  margin-bottom: 16px;
}
.signin_button {
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  background-color: #163e6e;
  font-size: 16px;
  font-weight: 600;
  width: 435px;
  color: #ffffff;
  line-height: 33px;
  margin-top: 16px;
  margin-bottom: 33px;
  height: 46px;
}
.signup_account {
  text-align: start;
  margin: 0px;
  color: #000000;
  font-size: 14px;
}
.signup_account a {
  font-weight: 500;
  color: #1b80e4;
  text-decoration: none;
}
.signup_footer {
  display: flex;
  align-items: center;
  margin: 8px -7px;
  margin: 40px 0px 20px 0px;
}
.signup_footer .left_border_signup {
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.49);
  margin: 0 8px;
}
.signup_footer .right_border_signup {
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.49);
  margin: 0 8px;
}
.signup_footer p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.74);
}
.footer_button {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  padding: 0px 29px;
  margin-right: 5px;
  height: 54px;
  width: 153px;
  cursor: pointer;
}
.footer_button_facebook {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  padding: 0px 29px;
  margin-left: 6px;
  height: 54px;
  width: 153px;
  cursor: pointer;
}
.footer_button img,
.footer_button_facebook img {
  width: 14px;
  height: 14px;
  margin-right: 9px;
  margin-bottom: 2px;
  align-self: center;
}
.footer_button p,
.footer_button_facebook p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  letter-spacing: 0px;
  text-align: center;
  color: rgba(0, 0, 0, 0.74);
  align-self: center;
}
.signup_footer_button {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  gap: 23px;
}
.Reset_button {
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  background-color: #163e6e;
  font-size: 16px;
  font-weight: 600;
  height: 46px;
  width: 381px;
  color: #ffffff;
  line-height: 33px;
  margin-top: 20px;
  margin-bottom: 6px;
}
.Back_to_Login {
  border: 1px solid #163e6e;
  border-radius: 15px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  width: 381px;
  color: #163e6e;
  line-height: 33px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  height: 46px;
  justify-content: center;
  margin-bottom: 36px;
}
.Forgot_Password_footer a {
  font-weight: 500;
  color: #1b80e4;
  text-decoration: none;
}
.forgate_heading {
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 33px;
  color: #163e6e;
  text-align: center;
  margin: 0;
  padding-right: 45px;
}
.Forgot_Password_footer {
  text-align: center;
  margin: 0px;
  color: #000000;
  font-size: 14px;
  padding-bottom: 10px;
}
.footer_text_signup {
  font-size: 14px;
  font-weight: 500;
  line-height: 33px;
  font-family: "Outfit", sans-serif;
  text-align: center;
  color: #000000;
  margin-top: 30px;
}
.inputStyle {
  box-sizing: border-box;
  width: 435px;
  height: 46px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #000000;
}
.inputStyleTogg {
  border: none;
}
.inputStyleToggle {
  box-sizing: border-box;
  width: 435px;
  height: 46px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #000000;
  display: flex;
  justify-content: space-between;
}
.inputStyleF {
  box-sizing: border-box;
  width: 381px;
  height: 46px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #000000;
}

.inputStyle:hover {
  border: 1px solid #163e6e;
}
.inputStyleToggle:hover {
  border: 1px solid #163e6e;
}
.inputStyleF:hover {
  border: 1px solid #163e6e;
}
.input_label {
  font-weight: 500;
  font-size: 15px;
  line-height: 33px;
}
.verifyemail_label {
  color: #1b80e4;
  font-weight: 500;
  font-size: 15px;
  line-height: 33px;
  cursor: pointer;
}

.signup_right {
  display: flex;
  justify-content: center;
  background: #163e6e;
  border-radius: 17px;
  width: 702px;
  height: 788px;
  font-family: "Outfit", sans-serif;
  padding-top: 70px;
  margin-right: 29px;
  margin-bottom: 20px;
}

.signup_right_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
}

.signup_right_content h1 {
  color: #ffffff;
  font-size: 38px;
  font-weight: 400;
  line-height: 40px;
  width: 82%;
  text-align: start;
}
.signup_right_content p {
  color: rgba(255, 255, 255, 0.49);
  padding-right: 69px;
  margin-bottom: 94px;
  margin-top: 30px;
  font-size: 18px;
  line-height: 33px;
  width: 82%;
}
.signup_container_small_typo {
  display: none;
}

.right_contant_img img {
  width: 100%;
  height: auto;
}

/* --------------------mobile menu css --------------------*/

.LnavbarPublic {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: -2px !important;
  z-index: 1;
}

.LnavbarPublic .nav_item a:hover {
  background: #163e6e;
  border-bottom: 3px solid #163e6e;
  font-weight: 600;
  background-clip: border-box, border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
}

.LnavbarPublic .nav_item .active {
  background: #163e6e;
  background-clip: border-box, border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 16px;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: #ffffff;
}
.lnav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: #ffffff;

  /* padding: 0px 50px; */
  padding: 0px 38px;
}
.nav-container a {
  width: 15%;
}
.lnav-container a {
  width: 15%;
}

.nav-logo {
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menuh {
  display: flex;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
}

.nav-links {
  color: #000000 !important;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}
.nav_links {
  color: #000000 !important;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  /* border-bottom: 3px solid transparent; */
}
.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #fff;
  position: absolute;
  top: 100px;
  left: -110%;
  height: 100vh;
  opacity: 1;
  transition: all 0.5s ease;
}
.nav-menu {
  display: none;
}
.nav-menu.Aactive {
  display: none;
}
.nav-menu.Aactive {
  padding: 40px;
  background: #ffffff;
  left: 0px;
  top: 80px;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 9999;
  height: 100vh;
  border-top: 1px solid #163e6e;
}
.nav-icon {
  display: none;
}
.nav-item .Aactive {
  border: none;
}
.nav-item a {
  width: 100%;
  padding: 0px;
}
.nav_item .Aactive {
  border: none;
}
.nav_item a {
  width: 100%;
  padding: 0px;
  cursor: pointer;
}

.nav-item {
  text-align: center;
  width: 100%;
  margin: 0px;
  padding: 5px 20px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.nav_item {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.032px;
  text-align: center;
  padding-right: 32px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

/* -------------------------------------home landing pages css-------------------------- */

.container_hfluid {
  width: 100%;
  float: left;
  /* background: #fef0fe; */
  background-color: #ffffff;
  /* background-color: #f4faff; */
  /* min-height: 100vh; */
  /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(254,240,254,1) 35%, rgba(255,255,255,1) 75%); */
  /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(254,240,254,1) 50%, rgba(255,255,255,1) 100%); */
}
.container_homefluid {
  width: 100%;
  float: left;
  /* background: #fef0fe; */
  background-color: #ffffff;
  /* background-color: #f4faff; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_work_fluid {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  float: left;
  background-color: #f4faff;
  /* min-height: 100vh; */
}
.hcontainer {
  /* width: 80%; */
  max-width: 1280px;
  clear: both;
  /* padding: 10px 20px; */
  padding: 10px;
  margin: 0 auto;
}

.container_footerfluid {
  background: #1b1d21;
}

.newfootercontent {
  font-style: normal;
  padding-top: 20px;
  /* padding: 20px 0px; */
}

.newfooter_innerleft {
  background: #292b2e;
  /* background: #2077d0; */
  /* background: #00c6bd; */
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  padding: 5px 10px;
}
.footer_input {
  font-size: 16px;
  align-self: center;
  font-weight: 500;
  color: #ffffff;
  padding-right: 20px;
  font-family: "Montserrat", sans-serif;
  padding-left: 10px;
  background-color: #292b2e;
  padding: 5px 10px;
  border: none;
}
.footer_input::placeholder {
  color: #ffffff;
}
.form_footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.sbscribe_buuton {
  padding: 5px 0px;
}
.sbscribe_buuton button {
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  background: #163e6e;
  border-style: solid;
  border-radius: 50px;
  border: none;
  /* border: 1px solid linear-gradient(90deg, rgba(223,41,175,1) 0%, rgba(130,41,237,1) 100%); */
  padding: 15px 22px;
  font-size: 14px;
  font-weight: 500;
}
.sbscribe_buuton button:hover {
  color: #163e6e;
  background: #ffffff;
}

.newfooter_innerimages ul li a {
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  margin: 0 5px 6px;
  text-align: center;
  width: 40px;
}

.newfooter_innerimages ul {
  display: flex;
  align-items: center;
}
.newfooter_left {
  display: flex;
  justify-content: space-between;
}
.newfooter_contentmiddle {
  display: flex;
  justify-content: space-between;
}

.newfooter_innermiddle h6,
.newfooter_right_inner h6 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: "Outfit", sans-serif;
}
.newfooter_innermiddle p,
.newfooter_right_inner p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.newfooter_right_inner p {
  padding-left: 19px;
}
.newfooter_middle {
  /* margin: 30px 0px; */
  margin-bottom: 10px;
  margin-top: 25px;
}
.newfooter_middlenav ul {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.footer_thankyou {
  color: #ffffff;
  margin-top: 7px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.thank_you > p {
  color: #000;
  margin-top: 7px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
.newfooter_middlenav ul li a {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}
.newfooter_middlenav ul li a:hover {
  border-bottom: 2px solid #ffffff;
}
.footer_error_flex {
  display: flex;
  flex-direction: column;
  height: 75px;
}
.newfooter_right_copy p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.newfooter_right_content {
  display: flex;
  justify-content: space-between;
}

.work_together_container {
  /* margin-bottom: 50px; */
  background: linear-gradient(
    90deg,
    rgba(22, 62, 110, 1) 45%,
    rgba(0, 198, 189, 1) 100%
  );
  /* min-height: 100vh; */
}
.work_together_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
  gap: 30px;
}
.work_together_inner h1 {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  font-family: "Outfit", sans-serif;
}
.work_together_inner p {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}
.work_together_inner a {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
}

.work_together_buuton button {
  font-family: "Outfit", sans-serif;
  color: rgb(22, 62, 110);
  background: #ffffff;
  border-style: solid;
  border-radius: 50px;
  border: 2px solid #ffffff;
  /* border: 1px solid linear-gradient(90deg, rgba(223,41,175,1) 0%, rgba(130,41,237,1) 100%); */
  padding: 15px 22px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}
.work_together_buuton > a > .join_button {
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  background: #163e6e;
  border-style: solid;
  border-radius: 50px;
  border: 2px solid #ffffff;
  /* border: 1px solid linear-gradient(90deg, rgba(223,41,175,1) 0%, rgba(130,41,237,1) 100%); */
  padding: 15px 22px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}
.work_together_buuton button:hover {
  background: #163e6e;
  border: 2px solid #ffffff;
  color: #ffffff;
}
.work_together_buuton > a > :hover {
  background: #ffffff;
  border: 2px solid #ffffff;
  color: #163e6e;
}
.newfooter_innerimages {
  align-self: center;
}
.why_work_with_us {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  margin-top: 50px;
  padding-top: 40px;
}

.why_flex {
  display: flex;
  margin-bottom: 20px;
  gap: 30px;
}

.why_flex_inner {
  display: flex;
  background: #d6e8ff;
  padding: 15px;
  border-radius: 10px;
}
.why_flex_inner p {
  margin-left: 10px;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.why_flex_inner img {
  width: 14px;
}
/* .why_work_with_right {
  width: 62%;
} */
.why_work_with_right h1 {
  font-size: 40px;
  font-weight: 600;
  color: #163e6e;
  font-family: "Outfit", sans-serif;
}
.have_offer_heading {
  font-size: 40px;
  font-weight: 600;
  color: #163e6e;
  text-align: center;
  margin-bottom: 30px;
  font-family: "Outfit", sans-serif;
  margin-top: 50px;
}
.why_work_with_right > p {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin: 20px 0px;
  font-family: "Montserrat", sans-serif;
}

/* our client think css */
.signup_checkbox {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.signup_checkbox p {
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #000000;
  margin-top: 7px;
}
.signup_checkbox span {
  margin-top: 7px;
}
.signup_checkbox span a {
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #163e6e;
  margin-left: 2px;
}

.our_client_card {
  width: 95% !important;
  background: #d6e8ff;
  border-radius: 20px;
}
.our_client_card_inner {
  /* padding: 30px; */
  padding: 30px;
}
.our_client_card_inner > p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
}

.our_client_think_flex {
  /* display: flex; */
  /* justify-content: space-between; */
}
.client_sub_inner {
  margin-bottom: 10px;
  /* display: flex;
    flex-direction: column;
    gap: 50px; */
}

.slick-prev:before,
.slick-next:before {
  color: #163e6e !important;
  /* background-color: white !important; Set background color to white */
  font-size: 30px !important; /* Set arrow size as per your requirement */
}

/* home section 1 css */
.home_section_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  /* padding-top: 100px; */
}

.home_section_inner_content {
  align-self: flex-start;
  width: 65%;
  margin-top: 20px;
}

.home_section_inner_content h1 {
  font-size: 40px;
  font-weight: 600;
  color: #163e6e;
  width: 60%;
  font-family: "Outfit", sans-serif;
}
.home_section_inner_content p {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin: 20px 0px;
  line-height: 25px;
  width: 60%;
  font-family: "Montserrat", sans-serif;
}
.home_section_inner_content .coonect_button {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
}

.brandbutton {
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  background: #163e6e;
  border-style: solid;
  border-radius: 50px;
  border: 1px solid #163e6e;
  padding: 15px 22px;
  font-size: 16px;
  font-weight: 500;
}
.brandbutton:hover {
  color: #163e6e;
  background: #ffffff;
  border: 1px solid #163e6e;
}

.coonect_button .connect_button_inner {
  font-family: "Outfit", sans-serif;
  color: #163e6e;
  background: #ffffff;
  border-style: solid;
  border-radius: 50px;
  border: 1px solid #163e6e;
  padding: 15px 22px;
  font-size: 16px;
  font-weight: 500;
}
.coonect_button .connect_button_inner:hover {
  color: #ffffff;
  background: #163e6e;
  border: 1px solid #163e6e;
}

.brandbutton.hover {
  color: #163e6e;
  background: #ffffff;
  border: 1px solid #163e6e;
}

.connect_button_inner.hover {
  color: #ffffff;
  background: #163e6e;
  border: 1px solid #163e6e;
}

.home_section_right {
  width: 43%;
}
.home_section_right img {
  width: 100%;
  height: auto;
}
/* -------------case studies css -----------------------*/
.case_card_conatiner {
  /* padding: 50px;
  border-radius: 20px;
  width: 32%;
  margin-bottom: 20px; */
  width: 32%;
  height: auto;
  box-shadow: 2px 3px 9px rgba(0, 0, 0, 0.07);
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;
}
.case_sub_container {
}
.case_images img {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}
.case_sub_container {
  padding: 20px;
}
.case_sub_container ul li:first-child {
  /* width: 40%; */
  margin-bottom: 10px;
  display: inline-block;
}

.case_sub_container ul li:last-child {
  padding-bottom: 10px;
}
.case_category {
  font-family: "Outfit", sans-serif;
  background: #e5e7eb;
  border-radius: 4px;
  border-width: 1px;
  font-size: 11px;
  color: #101727;
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 5px;
  padding: 10px;
}
.case_title {
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: normal;
  color: #163e6e;
}

.case_studies_inner_container {
  max-width: 900px;
}
.case_inner_image {
  width: 100%;
  margin-bottom: 10px;
}
.caseInner_head {
  max-width: 800px;
}
.case_inner_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.case_inner_text h1,
.case_studies_inner_container h1 {
  color: #163e6e;
  font-size: 30px;
  font-weight: 600;
  line-height: 25px;
  font-family: "Outfit", sans-serif;
  margin-bottom: 20px;
}
.case_inner_text p,
.case_studies_inner_container > p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 25px;
}
.case_inner_text p {
  text-align: justify;
}
/* background: url('../src/assets/images/iphoneframe.png') no-repeat center center; */
/* background-repeat: no-repeat;
background-position: center center;
width: 400px;
height: 500px;
display: flex;
justify-content: center;
align-items: center;
padding: 10px; */
/* border: 4px solid #000000;
border-radius: 20px; */

.video-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.iphone-frame {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.cover_images {
  width: 100%;
  height: auto;
  border-radius: 20px;
  border: 4px solid #000000;
}
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
}
.video_player {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.subheading_new {
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 25px;
}
.subHeadingnewe {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 25px;
}

.iphone_mid_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.iphone_mid_content img {
  width: 100px;
  height: 100px;
}
.iphone_mid_content a {
  font-size: 18px;
  text-decoration: none;
  color: #ffffff;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
}

/* home section connecting your brands css */
.connecting_brands_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  font-family: "Outfit", sans-serif;
}
.connecting_brands_left {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 20px; */
  width: 49%;
}

.list_disc_ul {
  list-style-type: disc;
  margin-bottom: 5px;
}
.connecting_brands_left img {
  border-radius: 20px;
}
.connecting_brands_right {
  align-self: center;
  width: 49%;
}
.connecting_brands_left_container {
  background: #9569ff;
  display: flex;
  border-radius: 20px 20px 20px 0px;
  padding: 30px 15px;
  gap: 20px;
  margin-bottom: 20px;
}
.connecting_brands_left_inner {
  margin-left: 20px;
}
.connecting_brands_left_inner img {
  width: 100%;
  height: auto;
}
.connecting_brands_right h1 {
  font-size: 40px;
  font-weight: 600;
  color: #b61dc4;
  width: 80%;
}
.connecting_brands_right p {
  font-size: 14px;
  font-weight: 400;
  color: #5c5c61;
  margin: 20px 0px;
  line-height: 25px;
  width: 80%;
}
.connecting_brands_right button {
  font-family: "Outfit", sans-serif;
  color: rgb(223, 41, 175);
  background: #ffffff;
  border-style: solid;
  border-radius: 50px;
  border: 1px solid rgb(223, 41, 175);
  /* border: 1px solid linear-gradient(90deg, rgba(223,41,175,1) 0%, rgba(130,41,237,1) 100%); */
  padding: 15px 22px;
  font-size: 14px;
  font-weight: 500;
}
.connecting_brands_right button:hover {
  color: #ffffff;
  background: linear-gradient(
    90deg,
    rgba(223, 41, 175, 1) 0%,
    rgba(130, 41, 237, 1) 100%
  );

  border: none;
  /* border: 1px solid linear-gradient(90deg, rgba(223,41,175,1) 0%, rgba(130,41,237,1) 100%); */
}
.connecting_brands_left_text h1 {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}
.connecting_brands_left_text p {
  font-size: 12px;
  font-weight: 400px;
  color: #ffffff;
  margin-top: 10px;
}
.connecting_brands_left_container img {
  border-radius: 0px 20px 20px 20px;
}

/* achievements_container css */

.achievements_container {
  background: #1b1d21;

  padding-top: 30px;
  padding-bottom: 70px;
  /* min-height: 100vh; */
}
.achievements_inner {
  display: flex;
  justify-content: space-between;
}
.achievements_inner_content {
  background: #292b2e;
  border-radius: 20px;
  padding: 60px;
  width: 29%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.achievement_heading {
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}
.achievements_inner_content h1 {
  color: #c1a8fe;
  font-size: 40px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}
.achievements_inner_content p {
  color: #b5b6b7;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
}

.brands_container {
  padding: 50px;
  border-radius: 20px;
  width: 32%;
  margin-bottom: 20px;
}
.careers_container {
  padding: 30px;
  border-radius: 20px;
  width: 32%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.brands_container.even,
.careers_container.even {
  background-color: #d6e8ff;
}

.brands_container.odd,
.careers_container.odd {
  background-color: #beecea;
}

.brands_icon {
  /* background: #163e6e; */
  width: 75px;
  height: 75px;
  text-align: center;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.careers_icon {
  /* background: #163e6e; */
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.careers_icon img {
  width: 20px;
  height: 20px;
}
.brands_icon img {
  width: 40px;
  height: 40px;
}
.brands_container h1 {
  color: #163e6e;
  font-size: 30px;
  font-weight: 600;
  width: 100%;
  margin: 30px 0px;
  font-family: "Outfit", sans-serif;
}
.careers_cards {
  width: 80%;
}
.careers_cards h1 {
  color: #163e6e;
  font-size: 25px;
  font-weight: 600;
  width: 100%;
  margin-bottom: 20px;
  /* margin: 10px 0px; */
  font-family: "Outfit", sans-serif;
}
.careers_top_containt {
  display: flex;
  flex-direction: column;
}
.careers_top_containt h1 {
  color: #163e6e;
  font-size: 40px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  /* margin: 10px 0px; */
  font-family: "Outfit", sans-serif;
}
.careers_top_containt p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  /* width: 60%; */
  align-self: center;
  max-width: 65%;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.brands_container p,
.careers_cards p {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  /* text-align: justify; */
}
.newfooter_innermiddle {
  display: flex;
  gap: 30px;
  width: 42%;
}
.newfooter_right_inner {
  display: flex;
  gap: 30px;
  width: 40%;
}
.we_have_offer_container {
  margin-bottom: 50px;
}
.we_have_solution_container {
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
}
.why_work_with_left {
  align-self: end;
  margin-bottom: 18px;
  width: 40%;
}
.why_work_with_left img {
  border-radius: 20px;
  width: 100%;
}
.why_flex {
  display: flex;
  margin-bottom: 20px;
  gap: 30px;
}

/* .why_work_with_right {
align-self: center;
} */

.have_solution_heading,
.brandinfluncers_forms {
  font-size: 40px;
  font-weight: 600;
  color: #163e6e;
  text-align: center;
  margin-bottom: 20px;
  font-family: "Outfit", sans-serif;
}
.brandinfluncers_forms {
  margin-bottom: 50px;
}
.brands_icon.even,
.careers_icon.even {
  background-color: #163e6e;
}
.brands_icon.odd,
.careers_icon.odd {
  background-color: #00c6bd;
}
.client_sub_inner img {
  width: 100px;
  height: 100px;
  align-self: center;
  /* border-radius: 50px; */
}
.client_sub_inner_sub h2 {
  font-size: 18px;
  font-weight: 600;
  color: #163e6e;
  margin-bottom: 2px;
  font-family: "Outfit", sans-serif;
  text-align: left;
}
.client_sub_inner_sub {
  flex-direction: column;
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.client_sub_inner_sub p {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}
.our_client_think {
  margin-bottom: 10px;
}
.our_client_think h1 {
  font-size: 40px;
  font-weight: 600;
  color: rgb(22, 62, 110);
  text-align: center;
  margin-top: 40px;
  font-family: "Outfit", sans-serif;
}

.we_have_offer_inner {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 50px;
}
.we_case_inner {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 50px;
}

.we_have_offer_button {
  display: flex;
  justify-content: center;
}
.we_have_offer_button .brands_button {
  font-family: "Outfit", sans-serif;
  color: rgb(22, 62, 110);
  background: #ffffff;
  border-radius: 2px;
  padding: 15px 22px;

  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;
}
.brand_border {
  border-top: 2px solid #163e6e;
  border-right: 1px solid #163e6e;
  border-bottom: 2px solid #163e6e;
  border-left: 2px solid #163e6e;
}

.influencer_border {
  border-top: 2px solid #163e6e;
  /* border-left:  none; */
  border-left: 1px solid #163e6e;
  border-bottom: 2px solid #163e6e;
  border-right: 2px solid #163e6e;
}
.we_have_offer_button .brands_button.selected {
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  background: #163e6e;
  border-style: solid;
  border-radius: 2px;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 500;
}

/* our_top_influencers_container css */

.our_top_influencers_container {
  background: #f4faff;
  padding: 30px 0px;
  /* margin-bottom: 60px; */
  min-height: 100vh;
  width: 100%;
  float: left;
  min-height: 100vh;
}
.top_influencer_heading {
  font-size: 40px;
  font-weight: 600;
  color: #163e6e;
  text-align: center;
  font-family: "Outfit", sans-serif;
  padding-top: 15px;
  margin-bottom: 30px;
}
.top_brandcollebr_heading {
  font-size: 40px;
  font-weight: 600;
  color: #163e6e;
  text-align: center;
  font-family: "Outfit", sans-serif;
  padding-top: 15px;
  margin-bottom: 15px;
}
.top_influencers_container_flex {
  display: flex;
  justify-content: space-between;
}
.payal_images {
  padding-bottom: 100px;
}
.payal_images img {
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: auto;
}
.influencers_card_container {
  margin-bottom: 70px;
  position: relative;
  padding: 0px 30px;
}
.influencers_card_details a {
  text-decoration: none;
  font-size: 25px;
  font-weight: 500;
  color: #163e6e;
  font-family: "Outfit", sans-serif;
}
.influencers_card_details {
  /* width: 22%; */
  background: #ffffff;
  padding: 20px;
  border-radius: 20px;
  bottom: -27px;
  /* bottom: -32px; */
  position: absolute;
  /* width: 90%; */
  width: 82%;
}

.influencers_card_details a > h1 {
  font-size: 25px;
  font-weight: 500;
  color: #163e6e;
  font-family: "Outfit", sans-serif;
}
.influencers_card_details > p {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
}
.our_client_think_flex {
  padding: 20px;
}
.influencers_card_details_subinner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.influencers_card_details_subinner > h1 {
  font-size: 19px;
  font-weight: 500;
  color: #163e6e;
  font-family: "Outfit", sans-serif;
  margin-right: 10px;
  align-self: center;
}
.influencers_card_details_subinner > p {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  align-self: center;
}
.influencers_card_details_subinner_container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.influencers_card_details_inner {
  display: flex;
  justify-content: space-between;
}
.influencers_card_details_inner a {
  align-self: center;
  margin-right: 20px;
}
.influencers_card_details_inner a img {
  width: 25px;
  height: 35px;
}
/* .top_influencers_container_flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
} */
.top_influencers_button {
  /* display: flex; */
  margin: 0 auto;
}
.top_influencers_button button {
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  background: #163e6e;
  border-style: solid;
  border-radius: 50px;
  border: 1px solid #163e6e;
  /* border: 1px solid linear-gradient(90deg, rgba(223,41,175,1) 0%, rgba(130,41,237,1) 100%); */
  padding: 15px 22px;
  font-size: 14px;
  font-weight: 500;
}
.top_influencers_button button:hover {
  color: #163e6e;
  background: #ffffff;
}

.brand_collebcontainer {
  background: #ffffff;
  padding: 50px 0px;
  /* margin: 50px 0px; */
  /* padding: 40px 0px; */
}
.leads_text{
  font-size: 14px;
  font-weight: 600;
  margin-left: 2px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
}
.brand_images {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  outline: none;
}

.brand_images img {
  width: 150px;
  /* height: 150px; */
  align-self: center;
}

.brands_drawer {
  padding: 0px 20px;
}
.brands_drawer_name_section {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  border-bottom: 4px solid #163e6e;
}
.brands_drawer_name_section h1 {
  color: #163e6e;
  font-size: 30px;
  font-weight: 500;
  font-family: "Outfit";
}

/* Home.css */

.top_icon_scroll {
  position: fixed;
  bottom: 10%;
  right: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.top_icon_scroll:hover {
  transform: translateY(-20px);
}

.checkbox_upper_back {
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 5px 20px;
  background: #f0f0f0;
  cursor: pointer;
}
.checked_background {
  border: 1px solid #163e6e;
  background: #163e6e;
  border-radius: 8px;
  padding: 5px 20px;
}

.radio-label {
  cursor: pointer;
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 5px 20px;
  background: #f0f0f0;
  font-family: "Montserrat", sans-serif;
}

.radio-label.selected {
  border: 1px solid #163e6e;
  background: #163e6e;
  border-radius: 8px;
  padding: 5px 20px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}
.mobile_function {
  display: flex;
  justify-content: space-between;
}
.mobile_function h5 {
  color: #163e6e;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}
.mobile_function h6 {
  color: #089352;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}
.align_self {
  align-self: center;
}
/* Your main CSS file or a dedicated stylesheet */
.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.otp-input {
  width: 50px;
  height: 40px;
  font-size: 2em;
  border-radius: 20px;
  border: 1px solid #163e6e;
  margin-right: 8px;
  text-align: center;
  color: #000000;
}

.otp-separator {
  display: none;
}

.submi_content_button {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.submit_content p {
  color: #163e6e;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.timerresendtext {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}
.resendcontainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.submit_content button {
  color: #ffffff;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  background: #163e6e;
  border: 1px solid #163e6e;
  padding: 8px 50px;
  border-radius: 20px;
}
.otpsubmitbutton {
  color: #ffffff;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  background: #163e6e;
  border: 1px solid #163e6e;
  padding: 8px 50px;
  border-radius: 20px;
}
.social_button_container {
  display: flex;
}
.social_url_button {
  padding: 8px;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  margin-right: 10px;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.social_url_button_selected {
  background: #163e6e;
  color: #ffffff;
}

.campaign_goal {
  display: flex;
}
.campaigngoal_label {
  margin-left: 23px;
  align-self: center;
}
.campaigngoal_label label {
  color: #163e6e;
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.campaigngoal_label p {
  margin: 0;
  white-space: wrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.5px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
}

/* .careers_css */

.job_section_heading {
  color: #163e6e;
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
}
.job_section_accoridain {
  color: #163e6e;
  font-family: "Outfit", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  margin: 30px 0px;
}
.exp_content h1 {
  color: #163e6e;
  font-family: "Outfit", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 30px;
}
.job_heading {
  color: #e60072;
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}
.job_heading_sub {
  color: #000000;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 13.5px;
  margin-top: 10px;
}
.job_heading_sub_ACCORIDIAN {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}
.skill_list {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.skill_content {
  margin-bottom: 20px;
}
.skill_container {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
.job_section_containt {
  margin: 20px 0px;
}
.apply_button,
.apply_button_job {
  color: #ffffff;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  background: #163e6e;
  border: 1px solid #163e6e;
  padding: 10px 50px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}
.apply_button_job {
  width: 100%;
}
.admin_note_heading {
  font-weight: 600;
  font-size: 30px;
  font-family: "Outfit", sans-serif;
  color: #163e6e;
  margin-bottom: 5px;
  text-align: center;
}
/* --------------------dashboard css------------------------- */

.container_insidefluid {
  width: 100%;
  float: left;
  /* background: #fef0fe; */
  background-color: #f9f8f5;
  /* background-color: #f4faff; */
  min-height: 100vh;
}
.dashboard_new_container {
  background: #1d322f;
}

.dashboard_new_content {
  display: flex;
  justify-content: space-between;
}
.dashboard_new_content_inner h1 {
}
.dashboard_new_content_inner p {
  font-size: 20px;
  font-weight: 400;
}
.dashboard_button button {
}
/* .brand_colleb_inner{
  display: flex;
  justify-content: space-between;
}
.brand_colleb_inner img{
 width: 300px;
 height: auto;
} */
/* media responsive screens css */

/* Styles for screen size up to 575px (small screens) */
@media (min-width: 376px) and (max-width: 505px) {
  /* .dashboard_banner > img {
    width: 400px !important;
    padding-left: 10px !important;
    padding-right: 17px !important;
  } */

  .banner_text p {
    display: none;
  }
}

@media (max-width: 400px) {
  .inputStyleF,
  .inputErrorF,
  .Reset_button,
  .Back_to_Login {
    width: 300px;
  }
  .signup_micro {
    display: none;
  }
}
@media (max-width: 500px) {
  .job_section_heading {
    line-height: normal;
    font-size: 30px;
  }
}
@media (max-width: 375px) {
  .form_footer {
    flex-direction: column;
  }
  .sbscribe_buuton {
    text-align: center;
  }
  .footer_input {
    text-align: center;
  }
}
@media (max-width: 375px) {
  .small_media {
    width: 80%;
  }

  .banner_text p {
    display: none;
  }
  .inputStyleF,
  .inputErrorF,
  .inputStyle,
  .inputStyleToggle,
  .inputError,
  .inputErrorToggle,
  .Reset_button,
  .Back_to_Login {
    width: 100%;
  }
  /* .dashboard_banner > img {
    width: 385px !important;
    padding-left: 10px !important;
    padding-right: 40px !important;
  } */
}

@media (max-width: 420px) {
  .inputStyle,
  .inputStyleToggle,
  .inputError,
  .inputErrorToggle {
    width: 300px;
  }
}

@media (max-width: 575px) {
  .brand_image {
    margin-right: 20px;
  }
  .careers_container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px 10px;
  }
  .careers_cards h1 {
    font-size: 20px;
    text-align: center;
  }
  .careers_cards p {
    text-align: center;
  }
  .careers_top_containt p {
    width: 100%;
  }
  .work_together_buuton button {
    margin-bottom: 10px;
  }
  .newfooter_middlenav ul {
    flex-direction: column;
    align-items: center;
  }
  .case_card_conatiner {
    width: 100% !important;
  }
  /* .input_text_error */
  .input_pass_error {
    display: none;
  }

  .right_contant_img img {
    width: 90%;
  }
  .input_text_error_span {
    color: red;
    font-size: 11px;
    /* display: inline-block; */
    display: block;
  }
  .transaction_Content {
    margin: 0px;
    padding: 22px;
  }

  .footerinner {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .blog_container {
    margin: 15px !important;
  }
  .faq_section_heading {
    font-size: 25px;
    line-height: normal;
  }

  .container {
    width: 100%;
  }

  .signup_container {
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0px;
  }

  .signup_forgate_container {
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0px;
  }

  .signup_card {
    gap: 20px;
    flex-direction: column;
    width: 75%;
  }
  /* .inputStyle,.inputError {
    width: 300px;
  } */
  .signin_button {
    width: 300px;
  }
  /* .right_contant_img {
    height: 200px;
    width: 200px;
  } */
  .signup_container_left {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .signup_right {
    width: 100%;
    height: 650px;
    margin-top: 0px;
    margin-right: 0px;
  }
  .signup_card_img {
    justify-content: space-around;
  }

  .signup_right_content p {
    padding-right: 0px;
    font-size: 19px;
  }
  .signup_right_content h1 {
    text-align: center;
    line-height: 36px;
    width: 100%;
    font-size: 28px;
  }
  .login_password {
    margin-right: 0px;
  }
  .login_email_verifiction {
    padding-right: 0px;
    width: 83%;
  }

  .forgate_heading {
    padding-right: 0px;
  }
  .signup_footer_button {
    padding-left: 0px;
    width: 75%;
  }
  .footer_button_facebook {
    margin-left: 0px;
    width: 100%;
    justify-content: center;
  }
  .footer_button {
    width: 100%;
    justify-content: center;
  }
  .foregate_typography {
    font-weight: 400;
    font-size: 20px;
    line-height: 26.3px;
    color: #000000;
    text-align: center;
    width: 81%;
    margin-top: 7px;
    margin-bottom: 40px;
  }

  /* notifiction_filter */
}
@media (min-width: 421px) and (max-width: 460px) {
  .inputStyle,
  .inputError,
  .inputStyleToggle,
  .inputErrorToggle,
  .signin_button {
    width: 330px;
  }
}
@media (min-width: 461px) and (max-width: 500px) {
  .inputStyle,
  .inputStyleToggle,
  .inputError,
  .inputErrorToggle,
  .signin_button {
    width: 353px;
  }
}
@media (min-width: 501px) and (max-width: 520px) {
  .inputStyle,
  .inputStyleToggle,
  .inputError,
  .signin_button {
    width: 380px;
  }
}
@media (min-width: 521px) and (max-width: 550px) {
  .inputStyle,
  .inputStyleToggle,
  .inputError,
  .inputErrorToggle,
  .signin_button {
    width: 400px;
  }
}
@media (min-width: 560px) and (max-width: 575px) {
  .inputStyle,
  .inputStyleToggle,
  .inputError,
  .inputErrorToggle,
  .signin_button {
    width: 430px;
  }
}

@media (max-width: 440px) {
  .signup_container_left h1 {
    padding-left: 10px;
  }
  .social_button_container {
    flex-direction: column;
    gap: 20px;
  }
  .signup_container_left_typo {
    display: none;
  }
  .signup_container_small_typo {
    display: block;
    padding-left: 10px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    margin: 0;
    margin-top: 10px;
  }
}
/* Styles for screens between 576px and 767.98px */
@media (max-width: 767.98px) {
  .blog_container {
    margin: 43px;
  }
  .blogcards_container ul li img,
  .blogcards_container {
    width: 100%;
  }
  .contact_right,
  .contact_left {
    width: 100%;
  }
  .contact_innercontainer {
    flex-direction: column;
  }
  .Landing_frame {
    /* width: 100%; 
    height: auto; 
    top: 320px; */
    display: none;
  }
  .Aboutus_images {
    width: 100%;
  }
  .aboutus_container_right_contant {
    flex-direction: column;
  }
  .blog_card_container {
    justify-content: center;
  }
  .aboutus_container_left {
    display: none;
  }
  .aboutus_container_innar {
    margin: 0px;
  }

  .aboutus_container_right {
    width: 90%;
  }
  .contactus_container_right {
    width: 90%;
  }
  .terms_container {
    padding: 20px;
    margin: 0px;
  }
  .nav-menuh {
    display: none;
  }

  .nav-icon {
    display: block;
  }
  .Account_container ul {
    margin: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .container {
    width: 100%;
  }

  .mywithdrawal_container {
    margin-right: 0px;
  }
  .ResultSubmissions_footer_container {
    flex-direction: column;
    gap: 10px;
  }
  .signup_container {
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0px;
  }
  .signup_card {
    gap: 20px;
  }
  .dashboard_header_mobile {
    display: none;
  }
  .adashboardcard_container {
    width: 40%;
  }
  .mdashboardcard_container {
    width: 47%;
  }
  .signup_container_left {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .signup_right {
    width: 100%;
    height: 750px;
    margin-top: 0px;
  }

  .signup_right_content p {
    padding-right: 0px;
  }
  .signup_right_content h1 {
    text-align: center;
    line-height: 36px;
  }
  .login_password {
    margin-right: 0px;
  }
  .login_email_verifiction {
    padding-right: 0px;
  }

  .forgate_heading {
    padding-right: 0px;
  }
  .signup_footer_button {
    padding-left: 0px;
  }
  .foregate_typography {
    font-weight: 400;
    font-size: 20px;
    line-height: 26.3px;
    color: #000000;
    text-align: center;
    width: 81%;
    padding-left: 27px;
    margin-top: 7px;
    margin-bottom: 40px;
  }
}

@media (max-width: 1140px) {
  .home_container {
    display: none;
  }
  .mobile_banner img {
    width: 100%;
  }
  .banner_text_home {
    position: absolute;
    top: 10%; /* Position the text vertically in the middle of the container */
    left: 50%; /* Position the text horizontally in the middle of the container */
    transform: translate(-50%, -50%); /* Center the text precisely */
    padding: 10px; /* Add padding around the text */
    color: #fff;
    font-size: 18px;
    text-align: center;
    width: 100%;
    /* Text color */
  }
  .mobile_banner {
    position: relative;
    display: inline-block;
    padding: 0px 8px;
  }
  .home_inner_mobile {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  .home_inner_mobile button {
    border-radius: 58px;
    background: #fff;
    border: 1px solid #163e6e;
    color: #163e6e;
    font-size: 18px;
    font-weight: 600;
    line-height: 165%;
    letter-spacing: 0.036px;
    width: 304px;
    height: 74px;
  }

  .home_inner_mobile p {
    padding: 10px;
    margin: 15px 0px;
    border: 1px solid #163e6e;
    border-radius: 20px;
    color: rgba(255, 255, 255, 0.89);
    background-color: #163e6e;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.048px;
  }
}
@media (max-width: 767.98px) {
  /* .hide-sidebar {
    margin-left: 0px !important;
  } */

  .dashboard_header_mobile {
    display: block;
    display: flex;
    justify-content: space-between;
  }

  .tutorial_video {
    width: 100%;
    height: auto;
  }
}
/* Styles for screens between 768px and 991.98px */

@media (min-width: 768px) and (max-width: 991.98px) {
  .container {
    width: 100%;
  }

  .blog_container {
    margin: 43px;
  }
  .blogcards_container ul li img,
  .blogcards_container {
    width: 100%;
  }
  .blog_card_container {
    justify-content: center;
  }

  .signup_container {
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0px;
  }
  .signup_card {
    gap: 20px;
  }
  .signup_container_left {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .signup_right {
    width: 100%;
    height: 750px;
    margin-top: 0px;
    margin-right: 0px;
  }

  .signup_right_content p {
    padding-right: 0px;
  }
  .signup_right_content h1 {
    text-align: center;
    line-height: 36px;
  }
  .login_password {
    margin-right: 0px;
  }
  .login_email_verifiction {
    padding-right: 0px;
  }
  .mdashboardcard_container {
    width: 47%;
  }
  .forgate_heading {
    padding-right: 0px;
  }
  .signup_footer_button {
    padding-left: 0px;
  }
  .foregate_typography {
    font-weight: 400;
    font-size: 20px;
    line-height: 26.3px;
    color: #000000;
    text-align: center;
    width: 81%;
    padding-left: 27px;
    margin-top: 7px;
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .Landing_frame {
    display: none;
  }

  .contact_right,
  .contact_left {
    width: 100%;
  }
  .contact_innercontainer {
    flex-direction: column;
  }

  .signup_container {
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0px;
  }
  .signup_card {
    gap: 20px;
  }
  .signup_container_left {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .signup_right {
    width: 100%;
    height: 750px;
    margin-top: 0px;
    margin-right: 0px;
  }

  .signup_right_content p {
    padding-right: 0px;
  }
  .signup_right_content h1 {
    text-align: center;
    line-height: 36px;
  }
  .login_password {
    margin-right: 0px;
  }
  .login_email_verifiction {
    padding-right: 0px;
  }

  .forgate_heading {
    padding-right: 0px;
  }
  .signup_footer_button {
    padding-left: 0px;
  }
  .foregate_typography {
    font-weight: 400;
    font-size: 20px;
    line-height: 26.3px;
    color: #000000;
    text-align: center;
    width: 81%;
    padding-left: 27px;
    margin-top: 7px;
    margin-bottom: 40px;
  }
}
@media (max-width: 1023.98px) {
  .dashboard_right_content {
    display: none;
  }
  .signup_forgate_container {
    flex-direction: column-reverse;
    gap: 50px;
    margin-top: 0px;
  }
  .signup_right {
    width: 100%;
    height: 750px;
    margin-top: 0px;
    margin-right: 0px;
  }
}

@-moz-document url-prefix() {
  .LnavbarPublic {
    overflow: hidden;
  }
}

/* media responsive screens css */
/* @media (min-width: 576px) */
@media (max-width: 575.99px) {
  .brands_container {
    padding: 28px;
  }
  .newfooter_innermiddle,
  .newfooter_right_inner {
    gap: 0px;
    align-items: center;
    flex-direction: column;
  }

  .why_flex_inner p {
    font-size: 14px;
  }
  .our_top_influencers_container {
    padding: 0px 15px;

    padding-top: 20px;
    padding-bottom: 20px;
  }
  .newfooter_right_copy p {
    text-align: center;
  }
  .newfooter_right_content {
    gap: 15px;
  }
  .blog_inner_container {
    font-style: normal;
    padding: 20px;
  }
  .blog_heading {
    font-size: 36px;
  }
  .contact_container {
    margin: 0px 10px;
  }
  .aboutus_container_right_contant {
    padding: 20px;
  }
  .brands_container h1 {
    margin: 16px 0px;
  }

  .why_flex {
    flex-direction: column;
  }

  .influencers_card_details {
    width: 80% !important;
  }
  .our_client_think h1 {
    margin: 0px;
    padding: 10px 0px;
  }
  .work_together_inner {
    text-align: center;
  }
  .home_section_inner_content .coonect_button {
    flex-direction: column;
  }
}
/* @media (min-width: 576px) and (max-width: 767.98px) { */

@media (max-width: 900px) {
  .nav-menu {
    display: block;
  }
  .nav-menu.Aactive {
    display: block;
  }
  .nav-menuh {
    display: none;
  }

  .nav-icon {
    display: block;
  }
  .lnav-container {
    padding: 0px 25px;
  }
}
@media (max-width: 768px) {
  /* solution css */

  .we_have_offer_inner {
    flex-direction: column;
  }
  .case_card_conatiner {
    width: 48%;
  }
  .brands_container {
    width: 100%;
  }
  .careers_container {
    width: 100%;
  }
  .careers_top_containt p {
    max-width: 90%;
  }
  .influencers_card_details {
    width: 83%;
  }

  /* footer css */

  .newfooter_left {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 20px;
    padding-bottom: 10px;
  }

  .newfooter_innermiddle,
  .newfooter_right_inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .newfooter_contentmiddle {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .newfooter_right_content {
    flex-direction: column;
    gap: 20px;
  }
  .newfooter_right_copy {
    align-self: center;
  }
  .our_client_card {
    width: 100%;
  }
  .our_client_think {
    margin-bottom: 0px;
  }

  .Influncer_arror_width {
    padding: 15px;
  }
  .home_section_container {
    flex-direction: column;
    align-items: center;
  }
  .home_section_inner_content h1,
  .home_section_inner_content p,
  .home_section_inner_content,
  .home_section_right {
    width: 90%;
    text-align: center;
  }
  .home_section_inner_content {
    width: 100%;
  }
  .home_section_right {
    width: 90%;
    padding-top: 20px;
  }
  .home_section_inner_content .coonect_button {
    justify-content: center;
  }
  .achievements_inner {
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
  }
  .achievements_inner_content {
    width: 100%;
  }

  .why_work_with_us {
    /* margin: 0px; */
    flex-direction: column;
  }
  .why_work_with_left {
    width: 100%;
  }
  .why_work_with_right {
    align-self: center;
  }
  .why_work_with_right h1,
  .why_work_with_right > p,
  .brands_container p,
  .brands_container h1,
  .client_sub_inner {
    text-align: center;
  }
  .why_flex {
    justify-content: center;
  }
  .have_offer_heading {
    margin: 0;
    padding: 50px 0px;
  }
  .brands_icon.even,
  .brands_icon.odd {
    margin: 0 auto;
  }
  .work_together_inner {
    padding: 30px 0px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1180px) {
  .careers_container.even,
  .careers_container.odd {
    width: 48%;
  }
  .careers_top_containt p {
    max-width: 80%;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .brands_container.even,
  .brands_container.odd {
    width: 48%;
  }

  .case_card_conatiner {
    width: 48%;
  }
  .newfooter_contentmiddle {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .newfooter_innermiddle,
  .newfooter_right_inner {
    justify-content: center;
    width: 100%;
  }
  .newfooter_right_content {
    flex-direction: column;
    gap: 20px;
  }
  .newfooter_right_copy p {
    text-align: center;
  }
  .our_top_influencers_container,
  .container_work_fluid,
  .container_homefluid,
  .achievements_container {
    min-height: auto;
  }
  .our_client_card {
    width: 100%;
  }
  .our_client_think_flex {
    padding: 10px;
  }
  .Influncer_arror_width {
    padding: 15px;
  }
  .nav_item {
    padding-right: 16px;
  }
  .home_section_container {
    flex-direction: column;
    align-items: center;
  }
  .home_section_inner_content h1,
  .home_section_inner_content p,
  .home_section_inner_content,
  .home_section_right {
    width: 90%;
    text-align: center;
  }
  .home_section_inner_content {
    width: 100%;
  }
  .home_section_right {
    width: 90%;
    padding-top: 20px;
  }
  .home_section_inner_content .coonect_button {
    justify-content: center;
  }
  .achievements_inner {
    flex-direction: column;
    gap: 20px;
    padding: 0px 20px;
  }
  .achievements_inner_content {
    width: 100%;
  }

  .why_work_with_us {
    flex-direction: column;
  }
  .why_work_with_left {
    width: 100%;
  }
  .why_work_with_right {
    align-self: center;
  }
  .why_work_with_right h1,
  .why_work_with_right > p,
  .brands_container p,
  .brands_container h1,
  .client_sub_inner,
  .our_client_card_inner > p {
    text-align: center;
  }
  .why_flex {
    justify-content: center;
  }
  .have_offer_heading {
    margin: 0;
    padding: 20px 0px;
  }
  .brands_icon.even,
  .brands_icon.odd {
    margin: 0 auto;
  }
  .work_together_inner {
    padding: 30px 0px;
  }
}
